import axios from 'axios'
import router from '../../router'

export default {
    namespaced: true,
    state: {
        admin: false
    },
    mutations: {
        SET_ADMIN(state, data) {
            state.admin = data
        }
    },
    actions: {
        registerKey(store, token) {
            sessionStorage.setItem('creative_edit', token)
            store.dispatch('login', token)
            router.push({ name: 'Home' })
        },
        autoLogin(store) {
            let token = sessionStorage.getItem("creative_edit");
            if (token) store.dispatch('login', token)
        },
        login(store, token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.get('/authedit').then(() => {
                store.commit('SET_ADMIN', true)
            }).catch((e) => {
                if(e.response && e.response.status === 401)
                    sessionStorage.removeItem('creative_edit')
            })
        },
    }
}