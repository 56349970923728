<template>
  <div class="recap_jt">
    <div class="title_color jeux">Jeux traditionnels</div>
    <div class="tableau">
      <div class="ligne head">
        <div class="e game_name">Game</div>
        <div class="e avant_red">Gains</div>
        <div class="e"></div>
        <div class="e"></div>
        <div class="e c avant_yellow">Billets <span>Jetons</span></div>
        <div class="e">Tips</div>
        <div class="e c"></div>
<!--        <div class="e c">Croupiers <span>Chefs</span></div>-->
      </div>
      <div class="ligne anim" :class="{ disabled: n.etat === false && n.pbj === 0 }" v-for="(n, k) in result_jt" :key="k">
        <div class="e game_name" :data-type="n.type">{{ n.game }}</div>
        <div class="e avant_red euro" :class="color(n.pbj)" :data-hide="n.etat === false && n.pbj === 0">{{ n.pbj | formatNumberdecimal }}</div>
        <div class="e">
          <el-tooltip v-if="n.variation" class="item" effect="dark" :content="(n.variation | formatNumber) + '%'" placement="top">
          <svg v-if="Math.sign(n.variation) !== -1 && n.variation != 0" class="green ico_trans" :title="n.variation | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
          <svg v-else-if="n.variation !== 0" class="red ico_trans" style="transform: rotate(180deg)" :title="n.variation | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
<!--          <b v-if="n.variation" class="green" :class="{red: Math.sign(n.variation) == -1}">{{ n.variation | formatNumber }}%</b>-->
          </el-tooltip>
        </div>
        <div class="e"><v-chart :options="n.graph" autoresize /></div>
        <div class="e c avant_yellow" :data-hide="n.etat === false && n.pbj === 0">{{ n.bills | formatNumber }}€ <span>{{ n.jetons | formatNumberdecimalsimple }}€</span></div>
        <div class="e not_important euro" :data-hide="n.etat === false && n.pbj === 0">{{ n.pourboires | formatNumberdecimalsimple }}</div>
        
        
        <div v-if="n.etat" class="croupier_chef e c not_important">{{ n.croupier }} <span>{{ n.chef }}</span></div>
        <div v-else class="croupier_chef e c not_important"><div class="closePastille"></div></div>
        
        
      </div>
      <div class="ligne end">
        <div class="e game_name"></div>
        <div class="e avant_red euro" :class="color(calc_col('pbj'))">{{ calc_col('pbj') | formatNumberdecimal }}</div>
        <div class="e"></div>
        <div class="e"></div>
        <div class="e c avant_yellow">{{ calc_col('bills') | formatNumber }}€ <span>{{ calc_col('jetons') | formatNumberdecimalsimple }}€</span></div>
        <div class="e not_important euro">{{ calc_col('pourboires') | formatNumberdecimalsimple }}</div>
        <div class="e c not_important"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
export default {
  name: "Recap_jt",

  data() {
    return {
      result_jt: [],
      result_jt_total: 0
    }
  },
  created() {
    this.$httpStatum.get('/jt').then((response) => {
      let r = response.data
            
      for(let n in r) {
        if(r[n].type !== 'thp') 
        {
          let color = '#da0531'

          if(r[n].type == 'ra')
            color = '#ffc107'
          else if(r[n].type == 'bj')
            color = '#ea6e48'
          else if(r[n].type == 'uth')
            color = '#0576ff'
          else if(r[n].type === 'p21')
            color = '#53C540'
          else if(r[n].type === 'punto')
            color = '#FFC107'
          
          let graph_x = []
          let graph_data = []
          
          for(let d in r[n].export_time) {
            graph_x.push(r[n].export_time[d].time)
            graph_data.push(r[n].export_time[d][r[n].game.toUpperCase()].total_gains)
          }
          
          let ret = {
            game: r[n].game,
            type: r[n].type,
            croupier: r[n].croupier,
            chef: r[n].chef,
            etat: r[n].etat,
            pbj: 0,
            bills: 0,
            jetons: 0,
            pourboires: 0,
            graph: {
              grid: { left: 0, top: 3, bottom: 3, right: 6 }, tooltip: {trigger: 'axis', backgroundColor: '#f0f2f5', textStyle: { color: '#7b7b7f' }},
              xAxis: { type: 'category', show: false, boundaryGap: false, data: graph_x },
              yAxis: { type: 'value', show: false },
              series: [{
                data: graph_data,
                type: 'line', showSymbol: false, lineStyle: { width: 2 }, itemStyle: { color: color }, areaStyle: {opacity: .1}
              }]
            },
            variation: 0
          }
          for(let d in r[n].bills) ret.bills += r[n].bills[d] * (d / 100)
          for(let d in r[n].jetons) ret.jetons += r[n].jetons[d] * (d / 100)
          for(let d in r[n].pourboires) ret.pourboires += r[n].pourboires[d] * (d / 100)
          
          if(r[n].export_time && r[n].export_time[r[n].export_time.length - 2]) {
            let last1 = r[n].export_time[r[n].export_time.length - 2][r[n].game.toUpperCase()].total_gains
            let last2 = r[n].export_time[r[n].export_time.length - 1][r[n].game.toUpperCase()].total_gains
            
            console.log('last1', last1)
            console.log('last2', last2)
            
            ret.variation = ((last2 - last1) / Math.abs(last1)) * 100
          }
          
          ret.pbj = ret.bills + ret.jetons - r[n].total_encaisse + parseFloat(r[n].tito)
          
          this.result_jt_total += ret.pbj
          this.result_jt.push(ret)
        }
      }
  
      this.$emit('total', {
        type: 'jt', value: this.result_jt_total
      })
    })
  },
  methods: {
    color(n) {
      if(Math.sign(n) === -1) return 'red'
      else if(n > 0) return 'green'
    },
    calc_col(key) {
      let r = 0
      for(let n in this.result_jt) 
        r += parseFloat(this.result_jt[n][key])
      return r
    }
  }
}
</script>

<style scoped>
  .game_name { text-transform: uppercase; -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent; }
  .echarts { width: 100%; height: 100%; position: relative; top: 3px }
  
  .croupier_chef { font-size: 12px!important; }
  
  .recap_jt .tableau .ligne { grid-template-columns: 14% 15% 8% 23% 15% 10% 15% !important }
  .recap_jt .tableau .ligne .game_name { padding: 0 15px }
  
  .closePastille { background: #f23f3f; width: 18px; height: 18px; border-radius: 4px; }
  
  
</style>