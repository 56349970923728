<template>
  <div class="recap_poker">

    <div class="title_color jeux">Poker</div>
    <div class="tableau">
      <div class="ligne head" style="grid-template-columns: 20% 32% 28% 20%">
        <div class="e">Game</div>
        <div class="e avant_red">Gains</div>
        <div class="e">Joueurs</div>
        <div class="e">Tips</div>
      </div>
      
      <div class="ligne anim" :class="{ disabled: n.etat === false }" style="grid-template-columns: 20% 32% 28% 20%" v-for="(n, k) in result_jt" :key="k">
        <div class="e game_name" :data-type="n.type">{{ n.game }}</div>
        <div class="e avant_red" :class="color(n.pbj)" :data-hide="n.etat === false && n.pbj === 0">{{ n.pbj | formatNumberdecimalsimple }}€</div>
        <div class="e"></div>
        <div class="e not_important" :data-hide="n.etat === false && n.pbj === 0">{{ n.pourboires | formatNumberdecimalsimple }}€</div>
      </div>
      <div class="ligne end" style="grid-template-columns: 20% 32% 28% 20%">
        <div class="e game_name"></div>
        <div class="e avant_red" :class="color('pbj')">{{ calc_col('pbj') | formatNumberdecimalsimple }}€</div>
        <div class="e"></div>
        <div class="e not_important">{{ calc_col('pourboires') | formatNumberdecimalsimple }}€</div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
export default {
  name: "Recap_jt",

  data() {
    return {
      result_jt: [],
      result_jt_total: 0
    }
  },
  created() {
    let getUsers = async () => {
      await this.$httpStatum.get('/jt').then((response) => {
        let r = response.data

        for (let n in r) {
          if (r[n].type === 'thp') {
            let ret = {
              game: r[n].game, type: r[n].type, croupier: r[n].croupier, chef: r[n].chef, etat: r[n].etat, pbj: 0, cagnotte: 0, pourboires: 0,
            }
            for (let d in r[n].cagnotte) ret.cagnotte += r[n].cagnotte[d] * (d / 100)
            for (let d in r[n].pourboires) ret.pourboires += r[n].pourboires[d] * (d / 100)

            ret.pbj = ret.cagnotte

            this.result_jt_total += ret.pbj
            this.result_jt.push(ret)
          }
        }

        this.$emit('total', {
          type: 'poker', value: parseFloat(this.result_jt_total)
        })
      })
    }
    getUsers()
      
  },
  methods: {
    color(n) {
      if(Math.sign(n) === -1) return 'red'
      else if(n > 0) return 'green'
    },
    calc_col(key) {
      let r = 0
      for(let n in this.result_jt)
        r += parseFloat(this.result_jt[n][key])
      return r
    }
  }
}
</script>

<style scoped>
.game_name { text-transform: uppercase; -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; }
.echarts { width: 100%; height: 100%; }
</style>