import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// import Dayjs from 'vue-dayjs';
let local_fr = require('dayjs/locale/fr')
// Vue.use(Dayjs);

import VueDayMomentJS from 'vue-day-moment-js'

const opions = {
  lang: local_fr
}

Vue.use(VueDayMomentJS, opions)

import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/fr'
import locale from 'element-ui/lib/locale'
import { Tooltip, Popover, Progress, DatePicker } from 'element-ui';
locale.use(lang)
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(DatePicker);

import Trend from "vuetrend"

import axios from 'axios'
// import VueAxios from 'vue-axios'






import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataZoom'
// import 'echarts/lib/component/c'
// import 'echarts/lib/chart/graph'


import parsePhoneNumber from 'libphonenumber-js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.locale('fr')
dayjs.extend(customParseFormat)

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

import { init } from './creative/init'


import bouton from './components/bouton'
import pop from '@/components/pop'

import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

let socket;

if (process.env.NODE_ENV === "production") {
  socket = io("https://statum.pleitek.com");
} else {
  socket = io('http://127.0.0.1:3810');
  // socket = io("https://dev.pleinair.fun");
}

init().then(() => {



  Vue.component('v-chart', ECharts)
  Vue.component('bouton', bouton)
  Vue.component('pop', pop)

  Vue.use(VueTelInput)
  Vue.use(VueSocketIOExt, socket)

  Vue.prototype.$phoneNumber = parsePhoneNumber
  Vue.prototype.$notyf = new Notyf({
    duration: 5000,
    position: {
      x: 'right',
      y: 'top'
    },
    types: [
      {
        type: 'success',
        background: '#75b80d'
      },
      {
        type: 'error',
        background: '#d2302a'
      }
    ]
  });
  Vue.prototype.$dayjs = dayjs;

  Vue.use(Trend)
  // Vue.use(VueAxios, axios)

  // Vue.axios.defaults.baseURL = 'http://127.0.0.1:4028/api/';
  
  Vue.prototype.$httpStatum = axios.create({
    // baseURL: process.env.VUE_APP_STATUM_API || 'https://statumapi.pleitek.com/api/'
    baseURL: process.env.VUE_APP_STATUM_API || 'http://127.0.0.1:4028/api/'
  });

  Vue.config.productionTip = false

  new Vue({
    router, store, render: h => h(App)
  }).$mount('#app')

})