<template>
  <div class="dashboard">
    
    
    <div class="subnav">
      
      <div></div>
      
      <div class="right">
        <div class="e small"><i class="material-icons">add_alert</i></div>
        <div class="e small"><i class="material-icons">camera_alt</i></div>
        <div class="sep"></div>
        <div class="e">Suivi billets <span class="caps">16</span></div>
        <div class="e"><i class="material-icons">trending_up</i>Alertes mas</div>
        <div class="e"><i class="material-icons">star</i>Jackpots</div>
        <div class="e"><i class="material-icons">casino</i>Parties en cours</div>
      </div>
    </div>
    
    <div class="width">
    
      <div class="global">
  <!--      <div class="graph"></div>-->
        <div class="recap_total shadow">
          <div class="elem">
            <div class="title title_color mas">Machines à sous</div>
  
            <transition name="slide-left">
            <div class="value" v-if="total.total !== 0" :class="{red: Math.sign(total.mas) == -1}">{{ total.mas | formatNumberdecimal }}€</div>
            </transition>
          </div>
          <div class="elem">
            <div class="title title_color rae">Roulettes électroniques</div>
            <transition name="slide-left">
            <div class="value" v-if="total.total !== 0" :class="{red: Math.sign(total.rae) == -1}">{{ total.rae | formatNumberdecimal }}€</div>
            </transition>
          </div>
          <div class="elem">
            <div class="title title_color jeux">Jeux traditionnels</div>
            <transition name="slide-left">
            <div class="value" v-if="total.total !== 0" :class="{red: Math.sign(total.jt + total.poker) == -1}">{{ total.jt + total.poker | formatNumberdecimal }}€</div>
            </transition>
          </div>
          <div class="elem">
            <div class="title title_color hj">Hors jeu</div>
            <transition name="slide-left">
            <div class="value" v-if="total.total !== 0" :class="{red: Math.sign(total.hj) == -1}">{{ total.hj | formatNumberdecimal }}€</div>
            </transition>
          </div>
          <div class="elem">
            <div class="title title_color total">Total</div>
            <transition name="slide-left">
            <div class="value" v-if="total.total !== 0" :class="{red: Math.sign(total.total) == -1}">{{ total.total | formatNumberdecimal }}€</div>
            </transition>
          </div>
        </div>
      </div>
      
      <div class="col_top">
        <Recap_jt class="jt shadow" @total="update_total"></Recap_jt>
        <Recap_poker class="poker shadow" @total="update_total"></Recap_poker>
        
        <div class="enter shadow">
          <div class="title_color entrees">Entrées</div>
        </div>
      </div>
  
      <Recap_zones class="shadow" type="mas" title="Machines à sous" @total="update_total"></Recap_zones>
      <Recap_zones class="shadow" type="rae" title="Roulettes électroniques" @total="update_total"></Recap_zones>

    </div>
    
  </div>
</template>

<script>
import Recap_jt from "@/components/home/Recap_jt";
import Recap_poker from "@/components/home/Recap_poker";
import Recap_zones from "@/components/home/Recap_zones";
export default {
  name: "home",
  data() {
    return {
      total: {
        mas: 0,
        rae: 0,
        jt: 0,
        poker: 0,
        hj: 0,
        total: 0
      }
    }
  },
  methods: {
    update_total(data) {
      this.total[data.type] = parseFloat(data.value)
      this.total.total = 0
      for(let n in this.total) {
        if(n != 'total')
          this.total.total += this.total[n]
      }
    }
  },
  components: {
    Recap_zones,
    Recap_jt,
    Recap_poker
  },
  watch: {
    user() {
      if(this.$store.state.creativeAuth.user && !this.$store.state.creativeAuth.user.data.permissions.includes('direct') && this.$store.state.creativeAuth.user.data.permissions.includes('poker')) {
        this.$router.push({ name: 'PokerResa' })
      }
      if(this.$store.state.creativeAuth.user && !this.$store.state.creativeAuth.user.data.permissions.includes('direct') && this.$store.state.creativeAuth.user.data.permissions.includes('bar')) {
        this.$router.push({ name: 'Bar' })
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.creativeAuth.user
    }
  },
  created() {
    
  },
  mounted() {
  }
}
</script>

<style scoped>

.dashboard { font-family: "Product Sans"; font-weight: normal; background: #f8f8f8; }


  /*f7f9fc*/
  
  .global { height: 100px; display: flex; background: #fff; margin: 10px 16px 0 16px  }
  .global .graph { width: 70%; border-right: 1px solid #e3e6ed; }
  .global .recap_total { display: flex; flex-direction: row; align-items: stretch; width: 100%; flex-wrap: wrap; }
  .global .recap_total .elem { width: calc(100% / 5); border-right: 1px solid #e3e6ed; }
  .global .recap_total .elem:last-child { border-right: 0; }
  .global .recap_total .elem .title { text-transform: uppercase; background: #fcf7f7; color: #111; font-weight: 900; height: 35px; display: flex; align-items: center; padding-left: 12px; margin: 4px; border-radius: 4px }
  .global .recap_total .elem .value { font-size: 24px; height: calc(100% - 48px); display: flex; align-items: center; padding-left: 14px; }

  /*.global .recap_total .elem:nth-child(1) .value { border: 3px solid #2263f4 }*/
  /*.global .recap_total .elem:nth-child(2) .title { background: #19b8d2 }*/
  /*.global .recap_total .elem:nth-child(2) .value { border: 3px solid #7a92a3 }*/
  /*.global .recap_total .elem:nth-child(3) .title { background: #75b80d }*/
  /*.global .recap_total .elem:nth-child(3) .value { border: 3px solid #4da74d }*/
  /*.global .recap_total .elem:nth-child(4) .title { background: #f2c03f }*/
  /*.global .recap_total .elem:nth-child(4) .value { border: 3px solid #a96233 }*/
  /*.global .recap_total .elem:nth-child(5) .title { background: #f23f3f }*/
  /*.global .recap_total .elem:nth-child(5) .value { border: 3px solid #d2302a }*/

  .col_top { display: flex; min-height: 300px; margin: 10px 16px 0 16px; }
  .col_top .jt { width: 60.4%; margin-right: 10px; background: #fff}
  .col_top .poker { width: 19.6%; margin-right: 10px; background: #fff  }
  .col_top .enter { width: 20%; background: #fff }
  

</style>