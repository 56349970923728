<template>
  <div class="img" v-bind:style="{ 'padding-bottom': heightF }" :data-admin="$store.state.creativeEdit.admin">
    <img :src="url" :alt="alt">
    <div v-if="$store.state.creativeEdit.admin" class="upload">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22.7 14.3L21.7 15.3L19.7 13.3L20.7 12.3C20.8 12.2 20.9 12.1 21.1 12.1C21.2 12.1 21.4 12.2 21.5 12.3L22.8 13.6C22.9 13.8 22.9 14.1 22.7 14.3M13 19.9V22H15.1L21.2 15.9L19.2 13.9L13 19.9M21 5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11V19.1L12.1 18H5L8.5 13.5L11 16.5L14.5 12L16.1 14.1L21 9.1V5Z" /></svg>
      Changer l'image
    </div>
    <input v-if="$store.state.creativeEdit.admin" @change="upload" type="file" id="file" accept="image/*">
  </div>
</template>

<script>
import Compressor from 'compressorjs';
export default {
  name: "imgUpload",
  data() {
    return {
      heightF: null,
      heightPx: null,
      uploadKey: null
    }
  },
  props: [ 'k', 'width', 'height', 'ratio', 'alt' ],
  created() {
    if(this.height) this.heightF = this.height + 'px'
    this.heightPx = this.height
    
    if(this.width && this.ratio) {
      this.heightPx = parseInt(this.width) / parseFloat(this.ratio)
      this.heightF = (parseInt(this.heightPx) * 100) / parseFloat(this.width) + '%'
    }

    // let img = this.$refs['img']
    // img.style.heigth = this.height
  },
  computed: {
    url() {
      if(!this.uploadKey)
        return process.env.VUE_APP_IMG + '/img/' + this.$content.content[this.k]
      else
        return process.env.VUE_APP_IMG + '/img/' + this.uploadKey
    }
  },
  methods: {
    upload(e) {
      let _this = this
      console.log('upload', e)
      this.uploadStart(e).then((d) => {
        console.log(d)
        _this.uploadKey = d.key
        this.$http.put('/project/content', {
          key: _this.k,
          content: d.key
        })
      }).catch((e) => console.log(e))
    },
    uploadStart(e) {
      let _this = this
      return new Promise(function(resolve, reject) {
        const file = e.target.files[0];
        if (!file) return;

        new Compressor(file, {
          quality: 1,
          mimeType: 'img/jpeg',
          strict: true,
          width: _this.width + 100,
          success(result) {
            let formData = new FormData();
            formData.append('file', result, result.name);
            formData.append('width', _this.width)
            formData.append('height', _this.heightPx)
            formData.append('meta', JSON.stringify({ "project": _this.$project }))
            // formData.append('min', 200)
            // formData.append('min', 200)
            _this.$http.post(process.env.VUE_APP_IMG + '/img/upload', formData ).then((upload_r) => {
              resolve({
                key: upload_r.data.key,
                blob: URL.createObjectURL(result)
              })
            }).catch((e) => reject(e))
          },
          error(err) {
            console.log(err.message);
          },
        });
      })
    }
  }
}
</script>

<style scoped>
  .img { position: relative; width: 100%; overflow: hidden; background: #ccc; }
  .img img { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
  .upload { position: absolute; background: rgba(0,0,0,.8); display: flex; align-items: center; justify-content: center; font-weight: 500;
    cursor: pointer; top: 50%; left: 50%; transform: translate(-50%, -60%); width: 180px; height: 44px; color: #fff; fill: #fff;
    transition: all .15s ease-out; opacity: 0; background: #41b883; box-shadow: -1px 3px 10px 0 rgb(0 0 0 / 6%); z-index: 2;
  }
  .img:hover .upload { transform: scale(1) translate(-50%, -50%); opacity: 1; }
  .img[data-admin]:after { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0,0,0,.5); z-index: 1; opacity: 0;  transition: all .15s ease-out; }
  .img[data-admin]:hover:after { opacity: 1; }
  .upload svg { margin-right: 6px; position: relative; top: 0px }
  input { opacity: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0; height: 100%; width: 100%; cursor: pointer; z-index: 3 }
</style>