<template>
  <div class="recap_zones">
      <div class="graph">
        <div class="title_color" :class="type">{{ title }} <span>{{ last_at }}</span></div>
        <v-chart :options="graph_option" autoresize v-if="load" />
      </div>

      <div class="tableau">
        <div class="ligne head" style="grid-template-columns: 21% 18% 18% 15% 18% 10%">
          <div class="e"></div>
          <div class="e avant_red">Net Win</div>
          <div class="e avant_violet">Réel</div>
          <div class="e avant_yellow">Bills</div>
          <div class="e">B.P</div>
          <div class="e">T. Occ</div>
        </div>
        <div class="ligne anim" style="grid-template-columns: 21% 18% 18% 15% 18% 10%" v-for="(n, k) in mas_zones" :key="k">
          <div class="e" style="border-top: 0px solid #fff;" :style="{ color: n.color, borderColor: n.color }">{{ k }}</div>
          <div class="e avant_red green euro" :class="{red: Math.sign(n['NetWin']) == -1}">{{ n["NetWin"] | formatNumberdecimal }}</div>
          <div class="e avant_violet euro">{{ n['Reel'] | formatNumberdecimal }}</div>
          <div class="e avant_yellow euro">{{ n['Bills'] | formatNumber }}</div>
          <div class="e not_important euro">{{ n['B.P'] | formatNumberdecimal }}</div>
          <div class="e c not_important">{{ (n.active * 100) / n['Nb'] | formatNumber }}% <span>{{ n.active }}/{{ n['Nb'] }}</span> </div>
        </div>
        
        <div class="ligne end" style="grid-template-columns: 21% 18% 18% 15% 18% 10%" >
          <div class="e"></div>
          <div class="e avant_red green euro" :class="{red: Math.sign(mas_total['NetWin']) == -1}">{{ mas_total["NetWin"] | formatNumberdecimal }}</div>
          <div class="e avant_violet euro">{{ mas_total['Reel'] | formatNumberdecimal }}</div>
          <div class="e avant_yellow euro">{{ mas_total['Bills'] | formatNumber }}</div>
          <div class="e not_important euro">{{ mas_total['B.P'] | formatNumberdecimal }}</div>
          <div class="e c not_important">{{ (mas_total.active * 100) / mas_total['Nb'] | formatNumber }}% <span>{{ mas_total.active }}/{{ mas_total['Nb'] }}</span> </div>
        </div>
        
      </div>
  </div>
</template>

<script>
export default {
  name: "Recap_zones",
  data () {
    return {
      
      load: false,

      mas_global: [],
      mas_zones: [],
      mas_total: [],
      mas_total_val: null,
      graph_label: [],
      graph_x: [],
      graph_data: [],
      
      last_at: null,
      
      graph_option: {}
      
      
    }
  },
  props: [ 'type', 'title' ],
  created() {

    this.$httpStatum.get('/' + this.type).then((response) => {
      console.log(response.data)
      this.mas_global = response.data.global
      
      
      this.mas_total = response.data.total
      this.mas_total_val = parseFloat(response.data.total['NetWin'])
      
      this.$emit('total', {
        type: this.type, 
        value: this.mas_total_val
      })
      

      let last = Object.keys(this.mas_global)
      this.last_at = last[last.length-1]
      this.mas_zones = this.mas_global[this.last_at]
      
      
      let zones = {}
      
      for(let n in this.mas_zones) {
        this.graph_label.push(n)
        zones[n] = []
      }
      
      for(let n in this.mas_global) {
        this.graph_x.push(n)
        
        for(let d in this.mas_global[n]) 
          zones[d].push(Math.round(this.mas_global[n][d]['NetWin'] * 100) / 100)
      }
      

      // let graph_tmp = []
      for(let n in zones) {
        this.graph_data.push({
          name: n,
          type:'line',
          smooth: 0.3,
          symbolSize: 4, showSymbol: false,
          itemStyle: { color: this.mas_zones[n].color, },
          lineStyle: { width: 3, opacity: .7, shadowColor: 'rgba(0,0,0,0)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .02},
          data: zones[n],
        })
      }

      this.graph_option = {
        // dataZoom: [{ bottom: '16px' }, { type: 'inside' }],
        tooltip: { trigger: 'axis', backgroundColor: '#f0f2f5', textStyle: { color: '#7b7b7f' }, axisPointer: { type: 'cross' } },
        legend: { left: '20px', data: this.graph_label, textStyle: { color: '#7b7b7f', fontFamily: 'Product Sans', fontWeight: '700' },inactiveColor: '#ccc' },
        grid: { left: '20px', right: '30px', top: '50px', bottom: '19px', height: 'auto', containLabel: true },
        xAxis: { type: 'category', boundaryGap: false, data: this.graph_x, axisLine: { lineStyle: { color: '#7b7b7f' } } },
        yAxis: { type: 'value', axisLabel: { formatter: '{value}€' }, axisPointer: { snap: true }, axisLine: { lineStyle: { width: 0, color: '#7b7b7f' } },
          splitLine: { lineStyle: { color: '#e6e5eb'  } }
        },
        series: this.graph_data, 
      }
      
      this.load = true
      
    })
  }
}
</script>

<style scoped>
  .recap_zones { display: flex; min-height: 300px; box-shadow: 0 4px 30px 0 rgba(0,0,0,.05); background: #fff; margin: 16px 16px; border-radius: 4px; min-height: 434px; }
  .recap_zones .graph { width: 50%; border-right: 1px solid #e6e5eb; }
  .recap_zones .tableau { width: 50%; }

  .echarts { width: 100%; height: calc(100% - 55px); }
  /*.echarts .canvas { width: 100%; height: calc(100% - 55px); }*/
</style>