<template>
  <div class="seo">
    <elem v-if="data.title" :k="data.title" design></elem>
    <elem v-if="data.description" :k="data.description" type="textarea" design></elem>
  </div>
</template>

<script>
export default {
  name: "seo",
  props: [ 'data' ],
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
  .seo { position: fixed; bottom: 0; left: 0; background: #000; z-index: 10 }
</style>