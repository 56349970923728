import Vue from 'vue'
import VueRouter from 'vue-router'
import Home2 from '../views/dashboard/home.vue'
// import Home from '../views/Home.vue'

import creative from './creative'
import jt from './jt'
import analyses from './analyses'
import embed from './embed'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true
    },
    component: Home2
  },
  {
    path: '/month',
    name: 'Analyse_month',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/dashboard/analyse_month')
  },
  {
    path: '/month/:month',
    name: 'Analyse_month_key',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/dashboard/analyse_month')
  },
  {
    path: '/year',
    name: 'Analyse_year',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/dashboard/analyse_year')
  },
  {
    path: '/year/:year',
    name: 'Analyse_year_key',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/dashboard/analyse_year')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/croupiers',
    name: 'Croupiers',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/croupiers')
  }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.matched.some((m) => m.meta.scrollToTop)) {
      return { x: 0, y: 0}
    }
    else
      return !savedPosition ? { x: 0, y: 0 } : savedPosition
  },
  routes: [
    ...routes,
    ...jt,
    ...analyses,
    ...embed,
    ...creative
  ]
})







router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('creative_token') == null) {
      next({
        path: '/login',
      })
    }
    else {
      next()
    }
  }
  else if(to.matched.some(record => record.meta.requiresNotAuth)) {
    if (localStorage.getItem('creative_token') != null) {
      next({
        path: '/profil',
      })
    }
    else {
      next()
    }
  } else {
    next()
  }
})



export default router
