import Vue from 'vue'
import Vuex from 'vuex'

import creativeAuth from "./modules/creativeAuth";
import creativeEdit from "./modules/creativeEdit";
import creativeCart from "./modules/creativeCart";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    creativeAuth,
    creativeEdit,
    creativeCart
  }
})
