<template>
  <div class="elem">
    <input v-if="$store.state.creativeEdit.admin && (!type || type === 'value')" :data-design="design" class="edit" type="text" v-model="value" placeholder="Inserez un texte">

<!--    <resizableTextarea v-else-if="$store.state.creativeEdit.admin && type && type === 'textarea'">-->
    <textarea v-else-if="$store.state.creativeEdit.admin && type && type === 'textarea'" :data-design="design" class="edit" v-model="value" v-autogrow placeholder="Inserez un texte"></textarea>

<!--    </resizableTextarea>-->
    
    <template v-else>{{ value }}</template>

    
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
      lastValue: null,
      interval: null
    }
  },
  props: [ 'text', 'type', 'k', 'design' ],
  created() {
    let _this = this
    this.value = this.$content.content[this.k]
    this.lastValue = this.$content.content[this.k]
    this.interval = setInterval(() => {
      if(_this.lastValue !== _this.value) {
        this.$http.put('/project/content', {
          key: _this.k,
          content: _this.value
        })
        console.log(_this.value)
      }
  
      _this.lastValue = _this.value
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  name: "elem"
}
</script>

<style scoped>
  span { display: inherit }
  .elem { white-space: pre-line }
</style>