<template>
  <div class="pop">
    <div class="bg" @click="$emit('close')"></div>
    <div class="pop_bloc">
      <div class="title">
        {{ title }}
        <div class="close" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
        </div>
      </div>
      
      
        <slot></slot>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "pop",
  props: [ 'title' ]
}
</script>

<style scoped>

  .pop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(20,20,20,.3); display: flex; align-items: center; justify-content: center; }
  .pop .bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
  .pop .pop_bloc { background: #fff; width: 500px; min-height: 300px;  border-radius: 6px; position: relative; animation: show .1s ease; z-index: 1 }
  .pop .pop_bloc .title { height: 70px; border-bottom: 1px solid #f1f2fb; display: flex; align-items: center; justify-content: space-between; padding-left: 20px; font-size: 20px; font-weight: 600; }
  .pop .pop_bloc .int { padding: 20px }

  .pop .pop_bloc .submitBlock { display: flex; justify-content: flex-end; border-top: 1px solid #f1f2fb; padding: 20px }



  @keyframes show {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0) scale(1); opacity: 1; }
  }
  
  .close { width: 70px; height: 70px; display: flex; align-items: center; justify-content: center; cursor: pointer }

</style>