
export default {
    namespaced: true,
    state: {
        etat: false,
        nb: false
    },
    mutations: {
        TOGGLE_ETAT(state) {
            state.etat = !state.etat
        },
        SET_NB(state, data) {
            state.nb = data
        }
    },
    actions: {
        sync(store) {
            let cart = localStorage.getItem('creative_cart')
            if(cart) {
                cart = JSON.parse(cart)
                if(cart)
                    store.commit('SET_NB', cart.length)
                else
                    localStorage.removeItem('creative_cart')
            }
        },
        toggleEtat(store) {
            store.commit('TOGGLE_ETAT')
        }
    }
}