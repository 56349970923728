<template>
  <button :data-loader="loader" @click="$emit('click')" :data-big="big" class="bouton">
    <!--      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.144 7.961-9.91 7.961-1.937 0-3.384-.397-4.394-.644-1 .613-1.594 1.037-4.272 1.82.535-1.373.722-2.748.601-4.265-.837-1-2.025-2.4-2.025-4.872 0-4.415 4.486-8.007 10-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.417.345 2.774.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-4.449 10.151c.246.277.369.621.369 1.034 0 .529-.208.958-.624 1.289-.416.33-.996.495-1.74.495-.637 0-1.201-.123-1.69-.367l.274-1.083c.494.249.993.375 1.501.375.293 0 .521-.056.686-.167.315-.214.334-.646.023-.892-.149-.117-.404-.236-.769-.357-1.097-.366-1.645-.937-1.645-1.716 0-.503.202-.917.604-1.243.404-.325.943-.488 1.614-.488.586 0 1.096.099 1.535.298l-.299 1.049c-.401-.187-.82-.28-1.254-.28-.267 0-.476.052-.627.153-.299.204-.293.57-.035.787.126.107.428.246.91.416.532.187.92.42 1.167.697zm12.205-.021c-.249-.28-.645-.518-1.181-.706-.475-.168-.776-.307-.899-.41-.243-.205-.249-.545.032-.738.146-.099.352-.148.609-.148.464 0 .87.104 1.274.295l.316-1.111-.022-.012c-.441-.199-.962-.3-1.55-.3-.675 0-1.225.166-1.632.495-.41.33-.618.757-.618 1.267 0 .791.562 1.377 1.67 1.745.357.122.612.239.757.353.292.231.28.637-.022.841-.157.108-.381.162-.667.162-.549 0-1.042-.145-1.522-.39l-.29 1.147c.549.273 1.122.38 1.728.38.749 0 1.34-.168 1.759-.502.422-.334.636-.776.636-1.313 0-.418-.127-.772-.378-1.055zm-6.644-3.005l-1.228 3.967-1.014-3.967h-1.791l-.366 5.75h1.229l.204-4.642h.018s.702 2.878 1.178 4.547h1.031c.794-2.419 1.261-3.936 1.399-4.547h.026c0 .813.045 2.36.136 4.642h1.298l-.309-5.75h-1.811z"/></svg>-->
    <svg v-if="loader && loader === true" class="loader" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm8 12c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-19 0c0-6.065 4.935-11 11-11v2c-4.962 0-9 4.038-9 9 0 2.481 1.009 4.731 2.639 6.361l-1.414 1.414.015.014c-2-1.994-3.24-4.749-3.24-7.789z"/></svg>
    <slot v-else></slot>
    <div class="t">{{ txt }}</div>
  </button>
</template>

<script>
export default {
  name: "bouton",
  props: [ 'loader', 'txt', 'big' ]
}
</script>

<style scoped>
  button { padding: 0 20px; height: 40px; display: flex; align-items: center; justify-content: space-between; background: #d2302a; border: 1px solid transparent; box-shadow: none; color: #fff;
    font-weight: 500; font-size: 0.875rem; fill: #fff; font-family: inherit; white-space: nowrap; border-radius: 5px; transition: all .15s ease; cursor: pointer; outline: none;
  }
  button[data-big] { font-weight: 500; font-size: 17px; height: 48px; padding: 0 12px }
  button .t { width: calc(100% - 24px); padding:  0 5px }
  button:hover { border-color: var(--vert); background: transparent; color: var(--vert); fill: var(--vert) }
  button .loader { fill: #999; animation: loader 3s infinite linear }
  button svg { width: 16px; height: 16px; }
  button[data-big] svg { width: 24px; height: 24px; }
  button[data-loader=true] { color: #999; border-color: #eaeaea; background: #fafafa; cursor: not-allowed; }
  
  @keyframes loader {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
</style>